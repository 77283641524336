import React from 'react'

export default function spinner() {
  return (
    <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
        </div>
  )
}

